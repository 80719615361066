<!-- 赛事中心-课程中心条目 -->
<template>
  <div class="item_lesson" @click="onItemClick">
    <img class="item_img" :src="item.eventCover" />

    <div class="bom">
      <span class="item_name">{{ item.eventName }}</span>
      <span class="item_time">{{ item.time }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  props: ["item"],
  data() {
    return {};
  },
  methods: {
    //条目点击事件
    onItemClick() {
      sessionStorage.setItem("lessonItem", JSON.stringify(this.item));
      this.$router.push({
        path: "ChapterCenter"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.item_lesson {
  position: relative;
  width: 584px;
  height: 370px;
  display: flex;
  flex-direction: column;
  background: white;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.3s ease; /* 平滑过渡效果 */

  .item_img {
    margin-top: 10px;
    width: 563px;
    height: 301px;
  }

  .bom {
    width: 563px;
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #434343;
    .item_name {
      font-weight: bold;
      font-size: 18px;
    }
    .item_time {
      font-size: 16px;
    }
  }
}

.item_lesson:hover {
  transform: scale(1.1); /* 放大1.1倍 */
}
</style>
