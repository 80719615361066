<!-- 赛事中心的课程中心 -->
<template>
  <div class="lesson_center">
    <div class="content" v-if="!showLoading">
      <!-- 列表子条目 -->
      <ItemLesson :item="item" v-for="(item, index) in courseData" :key="index"></ItemLesson>
    </div>

    <!-- 加载框 -->
    <Loading v-else></Loading>
  </div>
</template>

<script>
import ItemLesson from "./ItemLesson.vue";
export default {
  components: {
    ItemLesson
  },
  props: {},
  data() {
    return {
      //是否是加载状态
      showLoading: true,
      //数据
      courseData: []
    };
  },
  mounted() {
    //请求数据
    this.httpData();
  },
  methods: {
    //获取列表数据
    async httpData() {
      let param = this.getHttpParams();

      param.type = 1;

      let res = await this.$http.fetchPost(this.$api.COMPETITION_API, param);

      if (res.data.state == 200) {
        //隐藏Loading框
        this.showLoading = false;

        //表示请求成功
        this.courseData = res.data.data;
      } else {
        //直接将服务器返回的错误内容弹出提示
        this.showErrorMsg(this.getMesage(res.data.state));
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.lesson_center {
  width: $common_width;
  margin: 0 auto;

  .content {
    height: 100%;
    padding-top: 40px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}
</style>
